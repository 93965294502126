//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import $ from 'jquery/dist/jquery.min.js'
import { validationMixin } from 'vuelidate'
import {
  required,
  minLength,
  maxLength,
  email,
  numeric,
  helpers
} from 'vuelidate/lib/validators'
import isURL from 'validator/lib/isURL'
// import { getSign } from '@/plugins/utils'
import { v4 as uuidv4 } from 'uuid'
import { esaEncryptData } from '~/plugins/secret'

const corporateEmail = (value) => {
  const match = /^\w+[-.\w]*@(\w+[-.\w]*?\.\w{2,4})$/.exec(value)
  const forbiddenDomains = [
    'hotmail.com',
    'outlook.com',
    'gmail.com',
    'yahoo.com'
  ]
  if (
    !match ||
    !match.length ||
    match.length < 2 ||
    forbiddenDomains.includes(match[1].toLowerCase())
  ) {
    return false
  }
  return true
}
const url = helpers.withParams(
  { type: 'url' },
  value => !helpers.req(value) || !!isURL(value)
)

export default {
  mixins: [validationMixin],
  data () {
    return {
      current_language: this.$i18n.locale,
      submited: false,
      sendingData: false,
      errorMessage: '',
      form: {
        name: '',
        areaCode: this.getAreaCode(),
        phone: '',
        telephone: '',
        email: '',
        company: '',
        companyWebsite: '',
        companySize: '',
        country: '',
        industry: '',
        globalTransactions: '',
        latamTransactions: '',
        knowUs: '',
        usageCountries: [],
        solutions: [],
        message: ''
      },
      countriesList: require('@/components/contactus/countries.json'),
      countries: [],
      industry: this.getIndustryOpt(),
      employees: this.getEmployeesNumber(),
      transaction: this.getTransactions(),
      serviceCountriesOpt: this.getServiceCountryOpt(),
      solutionsOpt: this.getSolutions(),
      isSandBox: false,
      knowUsOption: this.getKnowUs()
    }
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(50)
      },
      phone: {
        required,
        numeric,
        minLength: minLength(7),
        maxLength: maxLength(11)
      },
      email: {
        required,
        email,
        corporateEmail,
        maxLength: maxLength(255)
      },
      company: {
        required,
        maxLength: maxLength(100)
      },
      companyWebsite: {
        required,
        url,
        maxLength: maxLength(300)
      },
      companySize: {
        required
      },
      country: {
        required
      },
      industry: {
        required
      },
      globalTransactions: {
        required
      },
      latamTransactions: {
        required
      },
      knowUs: {
        required
      },
      usageCountries: {
        required,
        minLength: minLength(1)
      },
      solutions: {
        required,
        minLength: minLength(1)
      },
      message: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(1500)
      }
    }
  },
  computed: {
    isAllSelected () {
      if (
        this.serviceCountriesOpt &&
        this.serviceCountriesOpt.length &&
        this.form.usageCountries &&
        this.form.usageCountries.length
      ) {
        return (
          this.serviceCountriesOpt.length === this.form.usageCountries.length
        )
      }
      return false
    }
  },
  mounted () {
    this.countries = this.getCountries()
    const { sandbox } = this.$route.query
    this.isSandBox = sandbox && parseInt(sandbox) === 1
  },
  beforeUpdate () {
    /* fix the solutions not updating on language change */
    if (this.current_language !== this.$i18n.locale) {
      this.current_language = this.$i18n.locale
      this.solutionsOpt = this.getSolutions()
      this.serviceCountriesOpt = this.getServiceCountryOpt()
      this.employees = this.getEmployeesNumber()
      this.transaction = this.getTransactions()
      this.form.areaCode = this.getAreaCode()
      this.countries = this.getCountries()
      this.industry = this.getIndustryOpt()
      this.knowUsOption = this.getKnowUs()
    }
  },
  methods: {
    getIndustryOpt () {
      const industries = []
      for (let i = 1; i < 14; i++) {
        industries.push({
          value: `${i}`,
          displayName: this.$i18n.t(`sales-contact-form-industry-${i}`)
        })
      }
      return industries
    },
    getCountries () {
      if (this.countriesList && this.countriesList.length) {
        const cntrs = [...this.countriesList]
        const uniqueIds = new Set()
        const uniqueCountries = cntrs.filter((country) => {
          const isDuplicate = uniqueIds.has(country.countryName)
          if (!isDuplicate) {
            uniqueIds.add(country.countryName)
            return true
          }
          return false
        })
        uniqueCountries.forEach((country) => {
          if (typeof country.code === 'string') {
            country.iso2 = country.code.substring(0, 2).toUpperCase()
            country.iso3 = country.code.substring(5).toUpperCase()
            if (this.$i18n.locale !== 'en') {
              country.displayName = this.$i18n.t(country.iso2.toLowerCase())
            } else {
              country.displayName = country.countryName
            }
          }
        })
        return uniqueCountries
      }
      return []
    },
    getAreaCode () {
      if (this.$i18n.locale === 'zh') {
        return '86'
      } else {
        return '55'
      }
    },
    getEmployeesNumber () {
      return [
        {
          value: 'none',
          displayName: this.$i18n.t('contact-us.company-size-opt1'),
          disabled: true
        },
        {
          value: '1-10',
          displayName: '1-10'
        },
        {
          value: '11-50',
          displayName: '11-50'
        },
        {
          value: '51-100',
          displayName: '51-100'
        },
        {
          value: '101-1000',
          displayName: '101-1000'
        },
        {
          value: '1000-More',
          displayName: '1000+'
        }
      ]
    },
    getTransactions () {
      return [
        {
          value: 'none',
          displayName: this.$i18n.t('contact-us.company-volume-opt1'),
          disabled: true
        },
        {
          value: '$0-$500k',
          displayName: '$0 - $500k USD'
        },
        {
          value: '$500k-$999k',
          displayName: '$500k - $999k USD'
        },
        {
          value: '$1M-$9M',
          displayName: '$1M - $9M USD'
        },
        {
          value: '$10M-$50M',
          displayName: '$10M - $50M USD'
        },
        {
          value: '$51M-$100M',
          displayName: '$51M - $100M USD'
        },
        {
          value: '$100M-More',
          displayName: '$100M+ USD'
        }
      ]
    },
    getSolutions () {
      return [
        { text: this.$i18n.t('contact-us.solutions-1'), value: '1' },
        { text: this.$i18n.t('contact-us.solutions-2'), value: '2' }
      ]
    },
    getKnowUs () {
      return [
        {
          value: 'Referral',
          displayName: this.$i18n.t('contact-us.know-us-referral')
        },
        {
          value: 'Linkedin',
          displayName: this.$i18n.t('contact-us.know-us-linkedin')
        },
        {
          value: 'Google',
          displayName: this.$i18n.t('contact-us.know-us-google')
        },
        {
          value: 'News / Blogs / Magazines',
          displayName: this.$i18n.t('contact-us.know-us-news')
        },
        {
          value: 'Other',
          displayName: this.$i18n.t('contact-us.know-us-other')
        }
      ]
    },
    getServiceCountryOpt () {
      return [
        {
          text:
            this.$i18n.locale === 'en'
              ? 'Brazil'
              : this.$i18n.t('br') || 'Brazil',
          value: 'BRA'
        },
        {
          text:
            this.$i18n.locale === 'en'
              ? 'Colombia'
              : this.$i18n.t('co') || 'Colombia',
          value: 'COL'
        },
        {
          text:
            this.$i18n.locale === 'en' ? 'Peru' : this.$i18n.t('pe') || 'Peru',
          value: 'PER'
        },
        {
          text:
            this.$i18n.locale === 'en'
              ? 'Chile'
              : this.$i18n.t('cl') || 'Chile',
          value: 'CHL'
        },
        {
          text:
            this.$i18n.locale === 'en'
              ? 'Mexico'
              : this.$i18n.t('mx') || 'Mexico',
          value: 'MEX'
        },
        {
          text:
            this.$i18n.locale === 'en'
              ? 'Ecuador'
              : this.$i18n.t('ec') || 'Ecuador',
          value: 'ECU'
        },
        {
          text:
            this.$i18n.locale === 'en'
              ? 'El Salvador'
              : this.$i18n.t('sv') || 'El Salvador',
          value: 'SLV'
        },
        {
          text:
            this.$i18n.locale === 'en'
              ? 'Guatemala'
              : this.$i18n.t('gt') || 'Guatemala',
          value: 'GTM'
        },
        {
          text:
            this.$i18n.locale === 'en'
              ? 'Panama'
              : this.$i18n.t('pa') || 'Panama',
          value: 'PAN'
        },
        {
          text:
            this.$i18n.locale === 'en'
              ? 'Uruguay'
              : this.$i18n.t('uy') || 'Uruguay',
          value: 'URY'
        },
        {
          text:
            this.$i18n.locale === 'en'
              ? 'Paraguay'
              : this.$i18n.t('py') || 'Paraguay',
          value: 'PRY'
        },
        {
          text:
            this.$i18n.locale === 'en'
              ? 'Dominican Republic'
              : this.$i18n.t('do') || 'Dominican Republic',
          value: 'DOM'
        },
        {
          text:
            this.$i18n.locale === 'en'
              ? 'Argentina'
              : this.$i18n.t('ar') || 'Argentina',
          value: 'ARG'
        },
        {
          text:
            this.$i18n.locale === 'en'
              ? 'Japan'
              : this.$i18n.t('jp') || 'Japan',
          value: 'JPN'
        },
        {
          text:
            this.$i18n.locale === 'en'
              ? 'China'
              : this.$i18n.t('cn') || 'China',
          value: 'CHN'
        },
        {
          text:
            this.$i18n.locale === 'en'
              ? 'Indonesia'
              : this.$i18n.t('id') || 'Indonesia',
          value: 'IDN'
        },
        {
          text:
            this.$i18n.locale === 'en'
              ? 'Malaysia'
              : this.$i18n.t('my') || 'Malaysia',
          value: 'MYS'
        },
        {
          text:
            this.$i18n.locale === 'en'
              ? 'The Philippines'
              : this.$i18n.t('ph') || 'The Philippines',
          value: 'PHL'
        },
        {
          text:
            this.$i18n.locale === 'en'
              ? 'Thailand'
              : this.$i18n.t('th') || 'Thailand',
          value: 'THA'
        },
        {
          text:
            this.$i18n.locale === 'en'
              ? 'Korea'
              : this.$i18n.t('kr') || 'Korea',
          value: 'KOR'
        },
        {
          text:
            this.$i18n.locale === 'en'
              ? 'Cambodia'
              : this.$i18n.t('kh') || 'Cambodia',
          value: 'KHM'
        }
      ]
    },
    validateState (name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    resetForm () {
      this.submited = false
      this.sendingData = false
      this.errorMessage = ''
      this.form = {
        name: '',
        areaCode: this.getAreaCode(),
        phone: '',
        email: '',
        company: '',
        companyWebsite: '',
        companySize: '',
        country: '',
        industry: '',
        globalTransactions: '',
        latamTransactions: '',
        knowUs: '',
        usageCountries: [],
        solutions: [],
        message: ''
      }
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    onSubmit () {
      const self = this
      this.errorMessage = ''
      this.$v.form.$touch()
      // console.log('this.$v.form.$anyError', this.$v.form.$anyError)
      if (this.$v.form.$anyError) {
        this.errorMessage = this.$i18n.t('contact-us.field-error')
        return
      }
      this.form.telephone = this.form.areaCode + '-' + this.form.phone
      // console.log('submit', this.form)
      // const key = {
      //   name: this.form.name,
      //   telephone: this.form.telephone,
      //   email: this.form.email,
      //   company: this.form.company,
      //   company_website: this.form.companyWebsite
      // }
      // const data = {
      //   name: this.form.name,
      //   telephone: this.form.telephone,
      //   email: this.form.email,
      //   company: this.form.company,
      //   company_website: this.form.companyWebsite,
      //   company_size: this.form.companySize,
      //   country: this.form.country,
      //   industry: this.form.industry,
      //   global_transactions: this.form.globalTransactions,
      //   latam_transactions: this.form.latamTransactions,
      //   how_know_us: this.form.knowUs,
      //   usage_countries: this.form.usageCountries,
      //   solutions: this.form.solutions,
      //   message: this.form.message
      // }
      // const sign = getSign(key)
      // console.log('data', { ...data, ...{ sign } })

      // this.sendingData = true
      // $.ajax({
      //   method: 'post',
      //   url: 'https://api.pagsmile.com/index.php?r=api%2Fcontactus',
      //   data: { data: JSON.stringify({ ...data, ...{ sign } }) }
      // })
      //   .then((res) => {
      //     this.sendingData = false
      //     const response = JSON.parse(res)
      //     if (Number(response.code) === 200) {
      //       this.submited = true
      //       /* baidu analytics submit start */
      //       window._agl && window._agl.push(['track', ['success', { t: 3 }]])
      //       /* baidu analytics submit end */
      //       return true
      //     } else {
      //       this.errorMessage = this.$i18n.t('contact-us.submit-error')
      //     }
      //   }).catch(() => {
      //     // console.log('catch')
      //     this.sendingData = false
      //     this.errorMessage = this.$i18n.t('contact-us.submit-error')
      //   })

      /* Pagsmile additional signup */
      // const pagsmileUrls = {
      //   dev: 'platform-dev.pagsmile.com',
      //   sandbox: 'platform-sandbox.pagsmile.com',
      //   prod: 'platform.pagsmile.com'
      // }
      // const pagsmileBaseUrl = this.isSandBox ? pagsmileUrls.sandbox : process.env.ENVIRONMENT === 'DEV' ? pagsmileUrls.dev : pagsmileUrls.prod
      // const pagsmileUrl = `https://${pagsmileBaseUrl}/dashboard/api/account/save-info`
      const uuid = uuidv4().replace(/-/g, '')
      const pagsmileUrl = `${process.env.APIURL}/website/common/account/apply`
      const data = {
        basic_info: {
          full_name: this.form.name,
          phone_number: this.form.phone,
          business_email: this.form.email,
          company_name: this.form.company,
          company_website: this.form.companyWebsite,
          company_size: this.form.companySize,
          country: this.form.country,
          industry: this.form.industry,
          global_transaction_volume: this.form.globalTransactions,
          latam_transaction_volume: this.form.latamTransactions,
          info_sources: this.form.knowUs,
          area_code: this.form.areaCode
        },
        region: this.form.usageCountries,
        need_solution: this.form.solutions,
        demand: this.form.message,
        endpoint: 'pagsmile'
      }
      const settings = {
        url: pagsmileUrl,
        method: 'POST',
        timeout: 0,
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          key: this.$encrypt(uuid),
          data: esaEncryptData(JSON.stringify(data), uuid)
        })
      }
      this.sendingData = true
      $.ajax(settings)
        .done(function (response) {
          // console.log('response ===> ', response)
          self.sendingData = false
          if (response.code === '10000') {
            // self.$gtm.push({ event: 'submitsuccess' })
            self.submited = true
            /* baidu analytics submit start */
            window._agl && window._agl.push(['track', ['success', { t: 3 }]])
            /* baidu analytics submit end */
            // self.$toast.success(response.msg, { timeout: 2000 })
            return true
          } else {
            console.log('Error: ', response.msg)
            self.$toast.error(response.msg)
          }
        })
        .catch((error) => {
          console.log('Error: ', error)
          self.sendingData = false
        })
    },
    handleSupport () {
      const SupportBtn = document.getElementsByClassName('support-btn')
      if (SupportBtn.length) {
        try {
          SupportBtn[0].click()
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error)
        }
      }
    },
    selectAllCountries () {
      const checkboxes = document.getElementsByName('usage-countries-field')
      const checkboxesArr = [...checkboxes]
      const checkedValue = !this.isAllSelected
      checkboxesArr.forEach((checkbox) => {
        if (checkbox.checked !== checkedValue) {
          checkbox.click()
        }
      })
    },
    showForm () {
      const that = this
      const { locale } = that.$i18n
      let formId = '' // en
      formId = 'f9fe3753-6479-4752-9cb3-8acbfe1f1cab'
      if (locale === 'zh') {
        formId = 'ccc585c6-d1fe-40a1-877f-86a0c6af7ce2'
      } else if (locale === 'pt') {
        formId = 'b8d52778-c4b5-4b06-bc3a-03b6c82ecb64'
      } else if (locale === 'es') {
        formId = '635abfa3-f800-4c76-b4e3-5f4bc55c7548'
      }
      // console.log('locale ------>', locale)
      that.sendingData = true
      hbspt.forms.create({
        region: 'na1',
        portalId: '47030039',
        formId,
        target: '#hs-contactus',
        locale,
        onBeforeFormInit: () => {},
        onFormReady: () => {
          that.sendingData = false
          that.$nextTick(() => {
            if (locale === 'zh') {
              const hsSupport = document.getElementById('hs-contactus')
              // hsSupport.classList.add('hs-contactus-errors')
              const config = { attributes: true, childList: true, subtree: true }
              const callback = function (mutationsList, observer) {
                for (const mutation of mutationsList) {
                  if (mutation.type === 'childList') {
                    mutation.addedNodes.forEach(node => {
                      if (node.nodeType === Node.ELEMENT_NODE) {
                        if (node.matches('ul')) {
                          // 找到了动态渲染的元素
                          node.firstChild.firstChild.innerText = '此字段是必填项'
                        } else if (node.classList.contains('hs_error_rollup')) {
                          node.firstChild.firstChild.firstChild.innerText = '请填写所有必填字段。'
                        }
                      }
                    })
                  }
                }
              }
              const observer = new MutationObserver(callback)
              observer.observe(hsSupport, config)
            }
            // try {
            //   const iframe = document.getElementsByClassName('hs-form-iframe')[0]
            //   const iframeDoc = iframe.contentDocument || iframe.contentWindow.document
            //   const elements = iframeDoc.getElementsByTagName('body')[0]
            //   const hsRichtext = elements.getElementsByClassName('hs-main-font-element')[0]
            //   const hsRichtextImg = hsRichtext.getElementsByTagName('img')[0]
            //   hsRichtextImg.style.width = '100%'
            //   hsRichtextImg.style.marginBottom = '30px'
            // } catch (error) {
            //   console.log(error)
            // }
          })
        },
        onBeforeFormSubmit: ($form) => {
          that.sendingData = true
        },
        onFormSubmitted: ($form) => {
          that.sendingData = false
          that.$bvModal.hide('contact-us-modal')
          that.$gtm.push({ event: 'submitsuccess' })
          /* baidu analytics submit start */
          window._agl && window._agl.push(['track', ['success', { t: 3 }]])
          /* baidu analytics submit end */
        }
      })
    }
  }
}
