//
//
//
//
//
//
//

import Home from '@/components/v3-components/home.vue'
/* import NoticePT from '~/components/common/NoticePT.vue' */

export default {
  components: {
    Home
    /* NoticePT */
  },
  layout: 'default-vthree',
  head () {
    return {
      title: 'Pagsmile | '.concat(this.$t('seo.slogan')),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('seo.description')
        }
      ]
    }
  }
}
