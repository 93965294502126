//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import HeroCommonButton from '~/components/v3/CommonV3/HeroCommonButton.vue'
import LearnMoreButton from '@/components/v3/CommonV3/LearnMoreButton.vue'

export default {
  name: 'HeroSection',
  components: {
    HeroCommonButton, LearnMoreButton
  },
  props: {
    title: {
      type: String,
      default: null
    },
    paragraph: {
      type: String,
      default: null
    },
    section: {
      type: String,
      default: null
    }
  }
}

